import React, { useContext, useState, useEffect, useRef } from "react";

// packages
import styled from "styled-components";
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import ReCAPTCHA from "react-google-recaptcha";
import Geocode from "react-geocode";

// configs and context
import { Context } from "../../contexts/Store";
import { accountsConfig } from "../../../axiosConfig";

// components
import LocationModal from "../modal/LocationModal";
import CaptchaTest from "./CaptchTest";
import OtpModal from "../modal/OtpModal";
import SuccessModal from "../modal/SuccessModal";

// lotties
import ButtonLoader from "../../ui/general/helpers/ButtonLoader";
import CountryModal from "../modal/CountryModal";
import SendButtonLoader from "../general/helpers/SendButtonLoader";

export default function Signup({ setActive, setLogin, OTP, setOTP }) {
    const { dispatch } = useContext(Context);
    const handleLocationModal = () => {
        setLocationModal(!isLocationModal);
    };
    const handleModal = () => {
        setModal(!isModal);
        setOTP("");
    };
    const handleSuccessModal = () => {
        setShow(!isShow);
    };
    const [isModal, setModal] = useState(false);
    const [isLocationModal, setLocationModal] = useState(false);
    const [locationValue, setLocationValue] = useState();
    const [locationLoading, setLocationLoading] = useState(false);
    const [locate, setLocate] = useState();
    const [isShow, setShow] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [error, setError] = useState(false);
    const [isCaptchaValid, setCaptchaValid] = useState(false);
    const [captchaMsg, setCaptchaMsg] = useState("");
    const [isEmailValidate, setEmailValidate] = useState(false);
    const [errorMessage, setErrorMessage] = useState("This field is required");
    const [isLoading, setLoading] = useState(false);
    const [mainError, setMainError] = useState(false);
    const [isCountryModal, setCountryModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [webCode, setWebCode] = useState();
    const [countryimage, setCountryImage] = useState();
    const [phoneCode, setPhoneCode] = useState();
    const [countryValue, setCountryValue] = useState();
    const [phoneValidation, setPhoneValidation] = useState("");
    const [border, setBorder] = useState("");

    useEffect(() => {
        loadCaptchaEnginge(6, "#fff", "#000");
    }, []);
    const handleCountryModal = () => {
        setCountryModal(!isCountryModal);
    };

    const getLiveLocation = () => {
        Geocode.setApiKey("AIzaSyA1Big55ZxwdB4Rr63kICLf9WdYN2yCqAc");
        setLocationLoading(true);
        console.log(Geocode, navigator);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                    // Store the coordinates in state
                    //   setLocation({ latitude, longitude });

                    // Reverse geocode the coordinates to get an address
                    Geocode.fromLatLng(latitude, longitude).then(
                        (response) => {
                            console.log(response);
                            const address =
                            response.results[0].formatted_address;
                            console.log(address,"address");
                            setLocate(address);
                            setLocationLoading(false);
                            setMainError(false);
                        },
                        (error) => {
                            console.error("Geocode Error: ", error);
                            setLocationLoading(false);
                            setErrorMessage("Unable to retrieve address");
                        }
                    );
                },
                (error) => {
                    console.error("Geolocation Error: ", error);
                    setLocationLoading(false);
                    setMainError(true);
                    setErrorMessage(
                        "Please allow location access."
                    );
                }
            );
        } else {
            setLocationLoading(false);
            setMainError(true);
            setErrorMessage("Geolocation is not supported by this browser.");
        }
    };

    document.onkeyup = enter;
    function enter(e) {
        if (e.which === 13) doSubmit(e);
    }

    const phoneNumberValidation = () => {
        if (phonenumber.length <= 9) {
            setPhoneValidation("Invalid phone number");
        } else {
            setPhoneValidation("");
        }
    };
    const doSubmit = (e) => {
        setMainError(false);
        let user_captcha = document.getElementById("user_captcha_input").value;
        if (username && email && locate && phonenumber) {
            if (validateCaptcha(user_captcha) === true) {
                setCaptchaValid(true);
                loadCaptchaEnginge(6);
                setLoading(false);
                handleSignUp();
                document.getElementById("user_captcha_input").value = "";
            } else if (!user_captcha) {
                setCaptchaMsg("Enter a valid captcha");
                setCaptchaValid(false);
                setLoading(false);
            } else {
                setCaptchaMsg("Invalid Captcha");
                setCaptchaValid(false);
                setLoading(false);
                document.getElementById("user_captcha_input").value = "";
            }
        } else {
            setError(true);
        }
    };

    // email validation
    const handleMail = (e) => {
        let str = e.target.value;
        setEmail(e.target.value);

        if (
            /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(str) &&
            str.includes(".com")
        ) {
            setEmailValidate(true);
            setErrorMessage("");
        } else {
            setEmailValidate(false);
            setErrorMessage("Enter a valid mail id");
        }
    };

    //recaptcha
    const recaptchaRef = useRef(null);
    //----------------------Signup config-----------------------------
    const handleSignUp = async () => {
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        setLoading(true);
        accountsConfig
            .post("api/v1/users/signup/set-profile/", {
                name: username,
                email: email,
                location: locate,
                country_web_code: "IN",
                phone: phonenumber,
                "g-recaptcha-response": token,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setModal(true);
                    setLoading(false);
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: {
                            name: username,
                            email: email,
                            location: locate,
                            country_web_code: "IN",
                            phone: phonenumber,
                        },
                    });
                } else if (StatusCode === 6001) {
                    setError(true);
                    setLoading(false);
                    setMainError(true);
                    setErrorMessage(data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //------------------------country code----------------------------------------

    useEffect(() => {
        accountsConfig
            .get("api/v1/general/countries/", {
                params: {
                    q: countryValue,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCountries(data.data);
                } else {
                    // setError(data.message)
                }
            })
            .catch((error) => {
                console.log(error);
                // setError(true)
            });
    }, [countryValue]);
    //--------------------------prevent letters from the input---------------------
    function restrictAlphabets(e) {
        var x = e.which || e.keycode;
        if (x >= 48 && x <= 57) return true;
        else return e.preventDefault();
    }
    //-----------------------------------------------------------------------------
    const onNameChange = (event) => {
        let value = event.target.value;
        value = value.replace(/[^A-Za-z, " "]/gi, "");
        setUsername(value);
        setError(false);
    };
    return (
        <Container>
            <TextHead>Sign Up</TextHead>
            <SubHead>Welcome! Please enter your details</SubHead>
            <Form>
                <Cover>
                    <EmailText>Name*</EmailText>
                    <Email
                        onClick={() => setBorder("name")}
                        type="text"
                        placeholder="Enter your name"
                        value={username}
                        className={border === "name" ? "active" : ""}
                        onChange={(myval) => {
                            setUsername(myval.target.value);
                            onNameChange(myval);
                        }}
                    />
                    <ErrorMessage>
                        {error && !username && (
                            <>
                                <span>This field is required</span>
                            </>
                        )}
                    </ErrorMessage>
                </Cover>
                <CoverBox>
                    <Covers className="email">
                        <EmailText>Email address*</EmailText>
                        <Email
                            onClick={() => setBorder("email")}
                            type="email"
                            placeholder="Email"
                            className={border === "email" ? "active" : ""}
                            onChange={(e) => handleMail(e)}
                        />
                        <ErrorMessage>
                            {!isEmailValidate && error && (
                                <>
                                    <span>Enter a valid email address.</span>
                                </>
                            )}
                        </ErrorMessage>
                    </Covers>
                    <Covers>
                        <EmailText>Phone number*</EmailText>
                        <Phone
                            onClick={() => setBorder("phone")}
                            className={border === "phone" ? "active" : ""}
                        >
                            <Flag>
                                <img
                                    src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/indianflag.svg"
                                    alt="alert"
                                />
                                {/* Donot delete this line */}
                                {/* <img src={countryimage} alt="flag" /> */}
                            </Flag>
                            <span>+91</span>
                            {/* Donot delete this line */}
                            {/* <span>{phoneCode}</span> */}
                            <input
                                type="text"
                                value={phonenumber}
                                maxLength="10"
                                placeholder="Phone number"
                                onChange={(e) => {
                                    setPhonenumber(e.target.value);
                                    setPhoneValidation("");
                                }}
                                onKeyPress={(e) => restrictAlphabets(e)}
                            />
                        </Phone>
                        <ErrorMessage>
                            {/* <span>{phoneValidation} </span> */}
                            {error && !phonenumber && (
                                <>
                                    <span>{phoneValidation}</span>
                                </>
                            )}
                            {/* {errorMessage === "Invalid phone number"
                                ? "Invalid phone number"
                                : ""} */}
                        </ErrorMessage>
                    </Covers>
                </CoverBox>
                <Cover
                    onClick={() => setBorder("location")}
                    // setLocationModal(true)}}
                >
                    <PassText>Location*</PassText>
                    <Location
                        className={border === "location" ? "active" : ""}
                        type="text"
                        placeholder="Pick your location"
                        value={locate}
                        onChange={(e) => setLocate(e.target.value)}
                        // disabled
                    />
                    {
                        locationLoading ?
                        <LoaderContainer>
                            <SendButtonLoader />
                        </LoaderContainer>
                        :
                        <Image
                            onClick={() => {
                                getLiveLocation();
                                // setLocationModal(true)
                            }}
                        >
                            <img
                                src="https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-user-images/location.svg"
                                alt="location icon"
                            />
                        </Image>
                    }
                    {/* <LocationModal
                        setLocate={setLocate}
                        locate={locate}
                        setLocationValue={setLocationValue}
                        locationValue={locationValue}
                        isLocationModal={isLocationModal}
                        handleLocationModal={handleLocationModal}
                        setLocationModal={setLocationModal}
                    /> */}
                    <CountryModal
                        setWebCode={setWebCode}
                        data={countries}
                        handleCountryModal={handleCountryModal}
                        isCountryModal={isCountryModal}
                        setCountryImage={setCountryImage}
                        setPhoneCode={setPhoneCode}
                        countryValue={countryValue}
                        setCountryValue={setCountryValue}
                    />

                    <ErrorMessage>
                        {error && !locate && (
                            <>
                                <span>This field is required</span>
                            </>
                        )}
                    </ErrorMessage>
                </Cover>
                <Captcha>
                    <CaptchaTest
                        isCaptchaValid={isCaptchaValid}
                        captchaMsg={captchaMsg}
                    />
                </Captcha>

                <Button
                    onClick={(e) => {
                        doSubmit(e);
                        phoneNumberValidation();
                    }}
                >
                    {mainError && (
                        <ErrorMessage className="main-error">
                            {errorMessage ===
                            "email - Enter a valid email address."
                                ? ""
                                : errorMessage === "Invalid phone number"
                                ? ""
                                : errorMessage}
                            {/* {errorMessage} */}
                        </ErrorMessage>
                    )}
                    {isLoading ? <ButtonLoader /> : <span>Create Account</span>}
                </Button>
                <SubText>
                    Already have an account?{" "}
                    <span onClick={() => setActive("Login")}>Login</span>
                </SubText>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    //This ref can be used to call captcha related functions in case you need.
                    sitekey="6LeTewMiAAAAAOJJMNKPlq3oG07U1XylrqchzXnJ"
                    size="invisible"
                    badge="bottomleft"
                />
            </Form>
            <OtpModal
                isModal={isModal}
                setModal={setModal}
                isShow={isShow}
                setShow={setShow}
                phonenumber={phonenumber}
                email={email}
                handleModal={handleModal}
                locate={locate}
                OTP={OTP}
                setOTP={setOTP}
            />
            <SuccessModal
                setLogin={setLogin}
                isShow={isShow}
                setShow={setShow}
                handleSuccessModal={handleSuccessModal}
            />
        </Container>
    );
}

const Flag = styled.div`
    width: 18px;
    min-width: 18px;
    border-radius: 50%;
    margin-right: 10px;
    /* cursor: pointer; */
    img {
        width: 100%;
        border-radius: 50%;
        display: block;
    }
`;
const Phone = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #e0e0e0;
    padding: 0px 15px;
    &:hover {
        border: 1px solid #0883e0;
    }
    &.active {
        border: 1px solid #0883e0;
    }
    height: 40px;
    border-radius: 2px;
    width: 100%;
    span {
        color: #707070;
        font-size: 14px;
    }
    input {
        font-size: 14px;
        color: #707070;
        outline: none;
        font-family: "outfit_light";
    }
    @media all and (max-width: 1080px) {
        height: 35px;
    }
    @media all and (max-width: 640px) {
        padding: 0px 8px;
    }
    @media all and (max-width: 360px) {
        padding: 12px 20px;
        font-size: 13px;
    }
    input {
        outline: none;
        width: 60%;
        @media all and (max-width: 640px) {
            width: 100%;
        }
    }
    span {
        display: inline-block;
        margin-right: 8px;
    }
`;
const Captcha = styled.div`
    position: relative;
`;
const ErrorMessage = styled.div`
    color: red;
    font-size: 13px;
    width: 100%;
    height: 10px;
    font-family: "outfit_light";
    &.captcha {
        width: 74%;
        height: 23px;
        text-align: right;
    }
    &.main-error {
        position: absolute;
        top: -20px;
        text-align: left;
    }
    & span {
        font-family: "outfit_light";
    }
    @media all and (max-width: 1080px) {
    }
`;
const Container = styled.div`
    background: #fff;
    padding: 25px;
    border-radius: 2px;
    @media all and (max-width: 1080px) {
        padding: 15px 30px;
    }
    @media all and (max-width: 980px) {
        padding: 25px 30px;
    }
    @media all and (max-width: 768px) {
        padding: 30px 50px;
    }
    @media all and (max-width: 640px) {
        padding: 15px 30px;
    }
    @media all and (max-width: 360px) {
        padding: 40px 20px;
    }
`;
const TextHead = styled.div`
    background: linear-gradient(123.89deg, #0e94f3 -23.5%, #05e8ba 111.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 26px;
    font-family: "outfit_light";
    @media all and (max-width: 1380px) {
        font-size: 24px;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 10px;
    }
    @media all and (max-width: 640px) {
        font-size: 22px;
        margin-bottom: 8px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 18px;
    }
`;
const SubHead = styled.div`
    margin-bottom: 15px;
    font-size: 13px;
    color: #929292;
    font-family: "outfit_light";
    @media all and (max-width: 1280px) {
        margin-bottom: 20px;
    }
    @media all and (max-width: 1080px) {
        margin-bottom: 10px;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const Form = styled.form``;
const LoaderContainer = styled.div`
    width: 10%;
    position: absolute;
    right: 0;
    bottom: 5px;
`;
const Image = styled.div`
    width: 4%;
    position: absolute;
    right: 16px;
    top: 35px;
    cursor: pointer;
    @media (max-width: 1380px) {
        top: 40px;
    }
    @media all and (max-width: 1360px) {
        width: 4%;
        top: 49%;
    }
    @media all and (max-width: 1080px) {
        width: 6%;
        top: 48%;
        right: 12px;
    }
    @media all and (max-width: 768px) {
        top: 46.5%;
        width: 5%;
    }
    @media all and (max-width: 640px) {
        width: 6%;
        top: 48%;
    }
    @media all and (max-width: 480px) {
        width: 5%;
        top: 48%;
    }
    @media all and (max-width: 360px) {
        width: 8%;
        top: 48%;
    }
`;
const CoverBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 1080px) {
        display: block;
    }
    @media all and (max-width: 980px) {
    }
    @media all and (max-width: 480px) {
    }
`;
const Cover = styled.div`
    margin-bottom: 20px;
    position: relative;
    @media all and (max-width: 1380px) {
        margin-bottom: 10px;
    }
`;
const Covers = styled.div`
    margin-bottom: 20px;
    width: 48%;
    @media all and (max-width: 1380px) {
        margin-bottom: 10px;
    }
    @media all and (max-width: 1280px) {
        margin-bottom: 12px;
    }
    @media all and (max-width: 1080px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    &.email {
        /* margin-right: 20px; */
        @media all and (max-width: 640px) {
            width: 100%;
            margin-right: unset;
        }
    }
`;
const EmailText = styled.div`
    font-family: "outfit_light";
    color: #929292;
    margin-bottom: 5px;
    font-size: 14px;
    @media all and (max-width: 1080px) {
        font-size: 15px;
    }
`;
const Email = styled.input`
    border: 1px solid #e0e0e0;
    padding: 0px 15px;
    height: 40px;
    border-radius: 2px;
    width: 100%;
    color: #707070;
    outline: none;
    font-family: "outfit_light";
    font-size: 14px;
    &:hover {
        border: 1px solid #0883e0;
    }
    &.active {
        border: 1px solid #0883e0;
    }
    @media all and (max-width: 1080px) {
        height: 35px;
    }
    @media all and (max-width: 640px) {
        padding: 0px 8px;
    }
    @media all and (max-width: 360px) {
        padding: 12px 20px;
        font-size: 13px;
    }
`;
const Location = styled.input`
    border: 1px solid #e0e0e0;
    padding: 0px 44px 0px 15px;
    height: 40px;
    border-radius: 2px;
    color: #707070;
    width: 100%;
    font-family: "outfit_light";
    font-size: 14px;
    &:hover {
        border: 1px solid #0883e0;
    }
    &.active {
        border: 1px solid #0883e0 !important;
    }
    @media all and (max-width: 480px) {
        padding: 0px 40px 0px 8px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
        padding: 0px 40px 0px 20px;
    }
`;
const PassText = styled.div`
    color: #929292;
    font-family: "outfit_light";
    margin-bottom: 5px;
    font-size: 14px;
    @media all and (max-width: 980px) {
        font-size: 15px;
    }
`;
const Button = styled.div`
    background: linear-gradient(255.47deg, #0881e0 0%, #05e8ba 102.09%);
    padding: 0 20px;
    height: 40px;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    & span {
        font-family: "outfit_light";
        letter-spacing: 0.8px;
    }
    @media all and (max-width: 480px) {
        font-size: 17px;
    }
    @media all and (max-width: 360px) {
        height: 40px;
        padding: 4px 20px;
    }
`;
const SubText = styled.div`
    color: #858585;
    margin-top: 10px;
    font-size: 14px;
    & span {
        color: #0881e0;
        cursor: pointer;
        font-family: "outfit_medium";
    }
    @media all and (max-width: 768px) {
        margin-top: 15px;
    }
    @media all and (max-width: 640px) {
        padding-bottom: 5px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
