import axios from "axios";

// LOCAL SERVER CONFIGS

// export const accountsConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/",
// });
// export const supportsConfig = axios.create({
//   baseURL: "http://127.0.0.1:8001/",
// });

//-----------------------------------------------------------------------

// DEMO SERVER CONFIGS

// export const accountsConfig = axios.create({
//     baseURL: "https://developers-accounts.stikcon.com",
// });
// export const supportsConfig = axios.create({
//     baseURL: "https://developers-supports.stikcon.com",
// });
// export const paymentsConfig = axios.create({
//     baseURL: "https://developers-payments.stikcon.com",
// });

//-----------------------------------------------------------------------

// LIVE SERVER CONFIGS

export const accountsConfig = axios.create({
    baseURL: "https://accounts.stikcon.com",
});
export const supportsConfig = axios.create({
    baseURL: "https://supports.stikcon.com",
});
export const paymentsConfig = axios.create({
    baseURL: "https://payments.stikcon.com",
});
